.background {
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
}

.background > .container {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.background > .container > .card {
  position: relative;
  background: #f6f8fd;
  border-radius: 20px;
  margin: 20px;
  padding: 20px 30px;
}

.background > .container > .card > .closeButton {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  background-image: url(../../assets/graphics/close-icon.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.background > .container > .card > .closeButton:hover {
  background-color: #0003;
}
